import preset from '@/@core/preset/preset'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/lib/locale/en'
import tr from 'vuetify/lib/locale/tr'

Vue.use(Vuetify)
const dinamik = localStorage.getItem('lang')

export default new Vuetify({
  lang: {
    locales: { en, tr },
    current: dinamik === 'en' ? 'en' : 'tr',
  },
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
