<template>
  <div class="vertical-shopping-cart-container pt-10 pt-md-0">
    <div
      v-if="!$vuetify.breakpoint.mdAndUp"
      class="text-right pa-1 elevation-10"
      style="position: fixed; top: 0; left: 0; width: 100%; z-index: 10"
    >
      <v-btn x-small block color="error" @click="closeBasket()">
        <span class="text-caption text-capitalize">Sepeti Kapat</span>
        <v-icon right>
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-snackbars :objects.sync="snackbar" width="500" top left transition="slide-y-transition">
      <template v-slot:action="{ close }">
        <v-btn icon small @click="close()">
          <v-icon small>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbars>

    <v-dialog v-model="dialog" eager width="500" persistent>
      <v-btn color="error" block @click="paymentCancel">
        <v-icon left>
          {{ icons.mdiClose }}
        </v-icon>
        İşlemi İptal Et
      </v-btn>
      <div id="credit"></div>
    </v-dialog>

    <v-dialog v-model="mesafeliStatus" scrollable max-width="600px">
      <v-card>
        <v-card-title>Mesafeli Satış Sözleşmesi</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <div v-html="mesafeliSatisSozlesmesi"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="mesafeliStatus = false"> Kapat </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="onBilgilendirmeStatus" scrollable max-width="600px">
      <v-card>
        <v-card-title>Ön Bilgilendirme Sözleşmesi</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <div v-html="onBilgilendirmeSozlesmesi"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="onBilgilendirmeStatus = false"> Kapat </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <perfect-scrollbar id="container" ref="scrollbar" class="ps-nav-menu-items" :options="perfectScrollbarOptions">
      <v-stepper
        v-if="$store.state['app-order'].basketItemCount > 0"
        v-model="basketStep"
        class="basket-stepper"
        vertical
        elevation="0"
      >
        <v-stepper-step
          :complete="basketStep > 1"
          :editable="basketStep > 1 && !loading"
          step="1"
          :class="$vuetify.breakpoint.mdAndUp ? 'px-2' : 'pa-0'"
        >
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
                :color="basketStep == 1 ? 'secondary' : 'primary'"
              >
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                  {{ icons.mdiBasket }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Sepet </v-list-item-title>

                <div v-if="basketStep != 1" class="d-block d-md-flex justify-space-between">
                  <div class="flex-grow-1">
                    <v-list-item-subtitle
                      class="text-caption font-weight-thin mb-1 mb-md-2"
                      style="text-shadow: none !important"
                    >
                      <span style="color: SlateGray" class="font-weight-medium">Ürün Sayısı : </span>
                      <span class="text-decoration-underline" style="color: black">{{ urunSayisi }} Adet</span>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                      v-if="urunSayisiTekirdag > 0 && urunSayisiKonya > 0 && $vuetify.breakpoint.mdAndUp"
                      class="text-caption font-weight-thin"
                      style="text-shadow: none !important"
                    >
                      <v-chip small label class="text-caption" color="secondary-light">
                        <span class="white--text"> Tekirdağ {{ urunSayisiTekirdag }}, Konya {{ urunSayisiKonya }}</span>
                      </v-chip>
                    </v-list-item-subtitle>
                  </div>
                  <div class="flex-grow-1">
                    <v-list-item-subtitle
                      class="text-caption font-weight-thin mb-1 mb-md-2"
                      style="text-shadow: none !important"
                    >
                      <span style="color: SlateGray" class="font-weight-medium">Paket Sayısı : </span
                      ><span class="text-decoration-underline" style="color: black">{{ toplamPaketSayisi }} Adet</span>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                      v-if="paketSayisiTekirdag > 0 && paketSayisiKonya > 0 && $vuetify.breakpoint.mdAndUp"
                      class="text-caption font-weight-thin"
                      style="text-shadow: none !important"
                    >
                      <!-- -->
                      <v-chip small label class="text-caption" color="secondary-light">
                        <span class="white--text"
                          >Tekirdağ {{ paketSayisiTekirdag }}, Konya {{ paketSayisiKonya }}</span
                        >
                      </v-chip>
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item-content>

              <v-list-item-action v-if="basketStep == 1 && $vuetify.breakpoint.mdAndUp">
                <v-btn icon @click="closeBasket()">
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep != 1 && $vuetify.breakpoint.mdAndUp">
                <v-chip v-if="basketStep != 1" large color="">
                  <span class="text-body-2 px-3"> Sepet Toplam Tutarı</span>
                  <v-chip color="info">
                    {{ toplamFiyat | currency }}
                  </v-chip>
                </v-chip>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep != 1 && !$vuetify.breakpoint.mdAndUp">
                <v-chip label class="text-caption">
                  {{ toplamFiyat | currency }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-stepper-step>

        <v-stepper-content step="1" :style="$vuetify.breakpoint.mdAndUp ? null : 'padding: 16px 45px 16px 8px;'">
          <v-card v-if="!sepetLoading" color="grey lighten-1" class="mb-6" rounded="lg">
            <v-card-text :class="!$vuetify.breakpoint.mdAndUp ? 'pa-0' : 'pt-0'">
              <v-list class="pa-0" color="grey" :dense="!$vuetify.breakpoint.mdAndUp">
                <div v-for="(line, index) in shoppingCartItems.detail" :key="`LIST-${index}`">
                  <v-hover v-slot="{ hover }">
                    <v-list-item
                      :three-line="!$vuetify.breakpoint.mdAndUp"
                      :two-line="$vuetify.breakpoint.mdAndUp"
                      :class="hover ? 'grey' : null"
                      class="px-2"
                    >
                      <v-list-item-avatar
                        v-if="$vuetify.breakpoint.mdAndUp"
                        :size="$vuetify.breakpoint.mdAndUp ? 75 : 50"
                      >
                        <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 75 : 50" tile>
                          <v-img
                            :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                            :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                            :src="
                              line.resim
                                ? 'https://portalapi.hatko.com/' + line.resim
                                : require('@/assets/images/misc/not-image.png')
                            "
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-action>
                        <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 140 : 100" color="transparent">
                          <CounterTo
                            :uid="line.uid"
                            :miktar="line.qty"
                            :birim="line.birim"
                            :paym-cond="line.paymCond"
                            :birim-ritim="50"
                            bg-color="counter"
                            button-color="black"
                            :light="true"
                            @refreshBasket="refreshBasket()"
                          />
                        </v-sheet>
                      </v-list-item-action>
                      <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-0 align-self-center'">
                        <!--   <div>
                          <v-chip
                            small
                            outlined
                            label
                            :color="
                              statusFind('fabrika', line.selected_plant, 'value') === '01' ? 'secondary' : 'warning'
                            "
                          >
                            {{ statusFind('fabrika', line.selected_plant, 'text') }}
                          </v-chip>
                        </div> -->
                        <v-list-item-title class="text-caption" style="color: rgb(63, 63, 63)">
                          {{ line.baslik }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="mt-1" style="color: SlateGray">
                          {{ (line.birimFiyat * line.qty) | currency }}
                          <span v-if="userData.role === 'PLSYR'">
                            - {{ line.paymCond == '003' ? 'Ön Ödemeli' : line.paymCond + ' Gün' }}</span
                          >
                        </v-list-item-subtitle>
                        <!--
                        <v-list-item-subtitle v-if="userData.role === 'PLSYR'">
                          <v-checkbox
                            v-model="line.isRamazan"
                            dense
                            hide-details
                            @change="isRamazanEvent(line.uid,line.qty,line.paymCond,line.selected_plant,line.isRamazan)"
                          >
                            <template v-slot:label>
                              <div class="text-caption">
                                Ramazan Kampanyası
                              </div>
                            </template>
                          </v-checkbox>
                        </v-list-item-subtitle>
                      -->
                      </v-list-item-content>

                      <v-list-item-action v-if="!$vuetify.breakpoint.mdAndUp || hover" class="ml-0">
                        <v-btn icon @click="deleteBasketItem(line.baslik, line.localCode)">
                          <v-icon>
                            {{ icons.mdiTrashCan }}
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-hover>
                  <v-divider></v-divider>
                </div>
                <!-- <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> Satır Toplam Fiyatı </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ satirToplamFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider> -->
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: SlateGray"> Ara Toplam </v-list-item-title>
                    <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                      {{ toplamBrutFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: SlateGray"> İndirim </v-list-item-title>
                    <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                      -{{ toplamIndirimTutari | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: SlateGray"> KDV </v-list-item-title>
                    <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                      {{ satirToplamKDV | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> Satır Brüt Fiyatı </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ toplamBrutFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> Toplam İndirim Tutarı </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ toplamIndirimTutari | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: SlateGray"> Toplam Fiyat </v-list-item-title>
                    <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                      {{ toplamFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <span v-if="userData.role === 'PLSYR'">
                  <v-divider></v-divider>
                  <!--  <v-list-item>
                    <v-list-item-content class="oneLine">
                      <v-list-item-title class="text-right">
                        <v-checkbox
                          v-model="hasPromotion"
                          dense
                          hide-details
                        >
                          <template v-slot:label>
                            <div class="text-caption">
                              Promosyon Uygulansın
                            </div>
                          </template>
                        </v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> 
                   
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content class="oneLine">
                      <v-list-item-title class="text-right">
                        <v-checkbox
                          v-model="hasMalFazlasi"
                          dense
                          hide-details
                        >
                          <template v-slot:label>
                            <div class="text-caption">
                              Mal Fazlası Kampanyası Uygulansın
                            </div>
                          </template>
                        </v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                -->
                </span>
              </v-list>
            </v-card-text>
            <v-card-actions class="px-2 px-md-4 pt-6 pt-md-2">
              <v-btn
                v-if="!errorShow"
                :plain="!$vuetify.breakpoint.mdAndUp"
                :small="!$vuetify.breakpoint.mdAndUp"
                text
                outlined
                class="text-capitalize"
                style="color: rgb(63, 63, 63)"
                @click="trashBasket()"
              >
                <v-icon left style="color: rgb(244, 82, 82)">
                  {{ icons.mdiTrashCan }}
                </v-icon>
                Sepeti Temizle
              </v-btn>
              <v-spacer></v-spacer>
              <v-alert v-if="errorShow" type="error" class="ma-0" text outlined dense>
                <span class="text-caption">Kayıtlı adresiniz olmadığı için devam edemiyoruz</span>
              </v-alert>
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                text
                class="text-capitalize"
                plain
                @click="closeBasket"
                style="color: rgb(63, 63, 63)"
              >
                Vazgeç
              </v-btn>
              <v-btn
                v-if="!errorShow"
                color="tertiary"
                class="text-capitalize white--text"
                x-large
                depressed
                :loading="loading"
                @click="basketStep2()"
              >
                Devam
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-sheet v-else color="grey" class="rounded-lg d-flex justify-center pa-6">
            <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
          </v-sheet>
        </v-stepper-content>

        <v-stepper-step
          :complete="basketStep > 2"
          :editable="basketStep > 2 && !loading"
          :class="$vuetify.breakpoint.mdAndUp ? 'px-2' : 'pa-0'"
          step="2"
        >
          <v-list class="pa-0">
            <v-list-item two-line>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
                :color="basketStep == 2 ? 'secondary' : 'primary'"
              >
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                  {{ icons.mdiTruckFast }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Teslimat </v-list-item-title>
                <div v-if="basketStep == 3" class="d-block d-md-flex justify-space-between">
                  <div class="flex-grow-1">
                    <v-list-item-subtitle class="text-caption font-weight-thin mb-1 mb-md-0" color="black">
                      <span style="color: SlateGray" class="font-weight-medium">Teslimat Adresi : </span>
                      <span class="text-decoration-underline" style="color: black">{{ address_title }}</span>
                    </v-list-item-subtitle>
                  </div>
                  <div class="flex-grow-1">
                    <v-list-item-subtitle class="text-caption font-weight-thin" style="text-shadow: none !important">
                      <span style="color: SlateGray" class="font-weight-medium">Teslimat Tarihi : </span>
                      <span class="text-decoration-underline" style="color: black">{{ deliveryDate }}</span>
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item-content>
              <v-list-item-action v-if="basketStep > 2 && $vuetify.breakpoint.mdAndUp">
                <v-chip large color="">
                  <span class="text-body-2 px-3"> Teslimat Tutarı</span>
                  <v-chip color="info">
                    {{ cargoPrice | currency }}
                  </v-chip>
                </v-chip>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep > 2 && !$vuetify.breakpoint.mdAndUp">
                <v-chip label class="text-caption">
                  {{ cargoPrice | currency }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-stepper-step>

        <v-stepper-content step="2" :style="$vuetify.breakpoint.mdAndUp ? null : 'padding: 16px 45px 16px 8px;'">
          <v-card color="grey lighten-1" class="mb-12">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large left>
                    {{ icons.mdiMapMarker }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Teslimat Adresi</v-list-item-title>
                  <v-list-item-subtitle> {{ address_title }}</v-list-item-subtitle>
                </v-list-item-content>
                <!-- 
                <v-list-item-action v-if="userData.role === 'PLSYR'">
                  <v-btn
                    class="text-capitalize"
                    depressed
                    :color="!namaSevkDurum ? 'secondary' : 'warning'"
                    @click="namaSevkDurum = !namaSevkDurum"
                  >
                    <span v-if="!namaSevkDurum">Nama Sevk</span>
                    <span v-else>Normal Sevk</span>
                  </v-btn>
                </v-list-item-action>
              -->
              </v-list-item>
            </v-list>

            <v-card-text>
              <template v-if="!namaSevkDurum">
                <v-item-group v-model="addressSelect" mandatory>
                  <v-row>
                    <v-col v-for="item in addressItems" :key="`${item.id}-address`" cols="12" md="6">
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          :color="active ? 'secondary' : 'nightDarkTon'"
                          class="pa-3"
                          dark
                          height="150"
                          @click="toggle"
                        >
                          <v-chip :color="active ? 'info' : 'primary'" small label>
                            {{ item.address_title }}
                          </v-chip>
                          <div class="pt-3 white--text font-weight-bold">{{ item.city_txt }} / {{ item.state }}</div>
                          <div
                            class="pt-1 white--text"
                            style="line-height: 1.25; font-size: 13px"
                            :class="active ? ' font-weight-medium' : null"
                          >
                            {{ item.address }}
                          </div>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </template>
              <div v-else>
                <v-autocomplete
                  v-model="namaSevkMusteri"
                  filled
                  label="Müşteri"
                  placeholder="Müşteri Seçin"
                  persistent-placeholder
                  :items="namaSevkMusteriItems"
                  dark
                  color="white"
                  hide-details
                  item-text="name1"
                  item-value="customer"
                  :menu-props="{ bottom: true, offsetY: true }"
                  @change="namaSevkMusteriChange"
                >
                </v-autocomplete>

                <v-item-group v-if="namaSevkMusteri" v-model="addressSelect" class="mt-3" mandatory>
                  <v-row>
                    <v-col v-for="item in namaSevkAddressItems" :key="`${item.id}-address`" cols="12" md="6">
                      <v-item v-slot="{ active, toggle }">
                        <v-card :color="active ? 'secondary' : ''" class="pa-3" dark height="150" @click="toggle">
                          <v-chip :color="active ? 'info' : 'grey'" small label>
                            {{ item.address_title }}
                          </v-chip>
                          <div class="pt-3 white--text font-weight-bold">{{ item.city_txt }} / {{ item.state }}</div>
                          <div
                            class="pt-1"
                            style="line-height: 1.25; font-size: 13px"
                            :class="active ? ' font-weight-medium' : null"
                          >
                            {{ item.address }}
                          </div>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </div>
            </v-card-text>

            <!-- 
            <v-divider class="my-3"></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large left>
                    {{ icons.mdiCalendar }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Teslimat Tarihi</v-list-item-title>
                  <v-list-item-subtitle> {{ deliveryDate | moment('Do MMMM YYYY dddd') }} Günü</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu v-model="menuDeliveryDate" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="text-capitalize"
                        depressed
                        :fab="!$vuetify.breakpoint.mdAndUp"
                        color="secondary"
                        v-on="on"
                      >
                        <v-icon :left="$vuetify.breakpoint.mdAndUp">
                          {{ icons.mdiRefresh }}
                        </v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Değiştir</span>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="deliveryDate"
                      scrollable
                      no-title
                      :min="minDate"
                      :max="maxDate"
                      :show-current="false"
                      :first-day-of-week="1"
                      @input="dateChange()"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-alert
              :type="$vuetify.breakpoint.mdAndUp ? 'warning' : null"
              color="warning"
              text
              class="mb-6 mx-4"
              :class="$vuetify.breakpoint.mdAndUp ? null : 'pa-2'"
            >
              <span class="text-caption"
                >Teslimat tarihinde yapacağınız değişiklikler ürün fiyatlarını güncelleyebilir.</span
              >
            </v-alert>
            -->

            <v-divider class="my-3"></v-divider>
            <!--  
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large left>
                    {{ icons.mdiText }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Teslimat Notu</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-text>
              <v-textarea
                v-model="buyerNote"
                auto-grow
                placeholder="Bir not girmek istiyorsanız buraya yazın"
                rows="1"
                row-height="15"
              />
            </v-card-text>
            -->
          </v-card>

          <div class="mb-6 d-flex align-center">
            <v-btn
              :plain="!$vuetify.breakpoint.mdAndUp"
              :small="!$vuetify.breakpoint.mdAndUp"
              text
              outlined
              class="text-capitalize"
              @click="backBasket(1)"
              style="color: rgb(63, 63, 63)"
            >
              <v-icon left style="color: rgb(63, 63, 63)">
                {{ icons.mdiChevronUp }}
              </v-icon>
              Sepet
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" text class="text-capitalize" plain @click="closeBasket">
              Vazgeç
            </v-btn>

            <v-btn
              color="tertiary"
              class="text-capitalize white--text"
              x-large
              depressed
              :loading="loading"
              @click="basketStep3()"
            >
              Devam
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="basketStep > 3" :class="$vuetify.breakpoint.mdAndUp ? 'px-2' : 'pa-0'" step="3">
          <v-list class="pa-0">
            <v-list-item two-line>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
                :color="basketStep == 3 ? 'secondary' : 'primary'"
              >
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                  {{ icons.mdiContactlessPayment }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Ödeme </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-stepper-step>

        <v-stepper-content :style="$vuetify.breakpoint.mdAndUp ? null : 'padding: 16px 45px 16px 8px;'" step="3">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card color="grey lighten-1" class="mb-12">
              <v-tabs
                v-model="tab"
                :class="$vuetify.breakpoint.mdAndUp ? 'px-6 pt-3' : null"
                background-color="transparent"
                style="box-shadow: none !important"
              >
                <v-tabs-slider color="secondary"></v-tabs-slider>

                <v-tab v-if="tabCHnone" :disabled="tabCHDisabled">
                  <div class="d-flex align-center">
                    <v-icon left>
                      {{ icons.mdiCircle }}
                    </v-icon>
                    <div class="text-capitalize">C/H Mahsuben</div>
                  </div>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" class="transparent pa-md-3">
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-alert type="warning" prominent dense text outlined class="my-alert">
                          Bu ödeme {{ $store.state.plasiyerCustomer }} carisi için C/H mahsuben yapılacaktır.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <!--  
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-data-table
                          :headers="headers"
                          :items="desserts"
                          :items-per-page="-1"
                          hide-default-footer
                          hide-default-header
                        >
                          <template #[`item.name`]="{ item }">
                            <span
                              v-if="
                                item.name === 'Toplam Risk' ||
                                item.name === 'Risk Limiti' ||
                                item.name === 'Kalan Açık Tutar'
                              "
                              class="font-weight-bold"
                            >
                              {{ item.name }}
                            </span>
                            <span v-else>
                              {{ item.name }}
                            </span>
                          </template>
                          <template #[`item.tutar`]="{ item }">
                            {{ item.tutar | currency }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  -->
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
              <v-container>
                <v-row v-if="taksitlendirme.installment && (tab == 0 || tab == 1)" dense class="mx-md-0">
                  <v-col cols="12">
                    <v-item-group v-model="taksitSecim" mandatory>
                      <v-row dense>
                        <v-col v-for="taksit in taksitlendirme.installment" :key="taksit.id" cols="6" md="2">
                          <v-item v-slot="{ active, toggle }">
                            <v-card
                              :color="active ? 'secondary' : ''"
                              class="d-flex align-center justify-center"
                              dark
                              height="75"
                              @click="toggle"
                            >
                              <div class="text-body-1" :class="active ? 'font-weight-medium white--text' : ''">
                                <span v-if="taksit.installment_text === 'Tek Çekim'" class="text-center">
                                  {{ taksit.installment_text }}
                                </span>
                                <span v-else> {{ taksit.installment_text.charAt(0) }} Taksit </span>
                              </div>
                            </v-card>
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-item-group>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="5"></v-col>
                  <v-col cols="12" md="7">
                    <v-card class="rounded-lg mr-0 mr-md-3" outlined color="nightDark">
                      <div class="d-flex align-center justify-space-between">
                        <v-card-title>
                          <div class="text-caption text-md-body-1 font-weight-medium">Ödenecek Tutar</div>
                          <div
                            v-if="taksitSayisi != 'T' && taksitSayisi != 0 && (tab === 0 || tab === 1)"
                            class="text-caption"
                          >
                            ( {{ taksitTutar | currency }} x {{ taksitSayisi }} )
                          </div>
                        </v-card-title>

                        <v-card-title class="justify-end">
                          <v-chip color="secondary" label :large="$vuetify.breakpoint.mdAndUp">
                            <span v-if="tab === 0 || tab === 1"> {{ toplamOdenecekTutar | currency }}</span>
                            <span v-else> {{ toplamOdenecekTutarHavale | currency }}</span>
                          </v-chip>
                        </v-card-title>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="ml-1 pb-3" dense>
                  <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="5"></v-col>
                  <v-col v-if="userData.role !== 'PLSYR'" cols="12" md="7">
                    <v-checkbox v-model="checkbox1" hide-details :rules="[v => !!v || 'Gerekli Alan']" dense>
                      <template v-slot:label>
                        <div class="text-caption" style="color: SlateGray">
                          <span
                            class="text-decoration-underline"
                            @click.stop="onBilgilendirmeStatus = !onBilgilendirmeStatus"
                          >
                            Ön Bilgilendirme Sözleşmesi
                          </span>

                          'ni okudum ve anladım.
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox v-model="checkbox2" hide-details :rules="[v => !!v || 'Gerekli Alan']" dense>
                      <template v-slot:label>
                        <div class="text-caption" style="color: SlateGray">
                          <span class="text-decoration-underline" @click.stop="mesafeliStatus = !mesafeliStatus">
                            Mesafeli Satış Sözleşmesi
                          </span>
                          'ni okudum, onaylıyorum.
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <div class="mb-6 d-flex align-center">
              <v-btn
                :plain="!$vuetify.breakpoint.mdAndUp"
                :small="!$vuetify.breakpoint.mdAndUp"
                text
                outlined
                class="text-capitalize"
                @click="backBasket(2)"
                style="color: rgb(63, 63, 63)"
              >
                <v-icon left style="color: rgb(63, 63, 63)">
                  {{ icons.mdiChevronUp }}
                </v-icon>
                Teslimat
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" text class="text-capitalize" plain @click="closeBasket">
                Vazgeç
              </v-btn>

              <v-btn
                color="tertiary"
                class="text-capitalize white--text"
                x-large
                depressed
                :loading="loading"
                :disabled="buttonDisabled"
                @click="basketStepCompleted()"
              >
                {{ buttonText }}
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
      </v-stepper>
      <v-card v-else flat tile height="100%">
        <v-list class="py-6 px-2">
          <v-list-item>
            <v-list-item-avatar
              :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
              :color="basketStep == 1 ? 'primary' : 'grey'"
            >
              <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                {{ icons.mdiBasketOff }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Sepet </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="basketStep == 1 && $vuetify.breakpoint.mdAndUp">
              <v-btn icon @click="closeBasket()">
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div class="d-flex align-center justify-center" style="height: calc(100% - 300px)">
          <div class="text-center">
            <v-icon color="primary" size="100">
              {{ icons.mdiBasketOff }}
            </v-icon>
            <div class="text-body-2 pt-1 pb-6">Sepetiniz Boş Lütfen Ürün Ekleyin</div>
            <v-btn depressed outlined small @click="closeBasket()"> Kapat </v-btn>
          </div>
        </div>
      </v-card>
    </perfect-scrollbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { getVuetify, useRouter } from '@core/utils'

import {
  mdiBank,
  mdiBasket,
  mdiBasketOff,
  mdiCalendar,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircle,
  mdiClose,
  mdiContactlessPayment,
  mdiCreditCard,
  mdiCreditCardPlus,
  mdiMapMarker,
  mdiRefresh,
  mdiText,
  mdiTrashCan,
  mdiTruckFast,
} from '@mdi/js'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'

// 3rd Party
import CardForm from '@/components/CardForm.vue'
import CounterTo from '@/components/CounterTo.vue'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import { postData } from '@utils'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { setTimeout } from 'timers'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    CounterTo,
    CardForm,

    // 3rd Party
    PerfectScrollbar,
    VSnackbars,
  },
  setup() {
    const birinciKart = ref(null)
    const ikinciKart = ref(0)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const buttonText = ref('Alışverişi Tamamla')
    const kredikartino = ref('')
    const kredikartiadsoyad = ref('')
    const kredikartiinstallment = ref([])
    const kredikartiay = ref('')
    const kredikartiyil = ref('')
    const kredikarticvv = ref('')
    const { router } = useRouter()
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const shoppingCartItems = ref([])
    const addressItems = ref([])
    const mesafeliStatus = ref(false)
    const onBilgilendirmeStatus = ref(false)
    const errorShow = ref(false)
    const bankalar = ref([])
    const cargoPrice = ref(0)
    const toplamOdenecekTutar = ref(0)
    const toplamOdenecekTutarHavale = ref(0)
    const formValidate = ref([])
    const bankItem = ref(0)
    const buyerNote = ref(null)
    const valid = ref(true)
    const sepetLoading = ref(true)
    const cardNumberValidate = ref(false)
    const cardNameValidate = ref(false)
    const cardMountValidate = ref(false)
    const cardYearValidate = ref(false)
    const cardCvvValidate = ref(false)
    const kkAdSoyad = ref(null)
    const kkAy = ref(null)
    const kkYil = ref(null)
    const kkCvv = ref(null)
    const kkNo = ref(null)
    const selectedInstallment = ref(null)
    const bankaKodu = ref(null)
    const cardFamily = ref(null)
    const cardBankName = ref(null)
    const number = ref(2)
    const musteri = ref(1)
    const fabrika = ref(1)
    const tarih = ref(1)

    const isMulti = ref(false)
    const basketStep = ref(1)
    const minDate = ref(null)
    const maxDate = ref(null)
    const tab = ref(0)
    const deliveryDate = ref(null)
    const loading = ref(false)
    const dialog = ref(false)
    const menuDeliveryDate = ref(false)
    const checkbox1 = ref(userData.role === 'PLSYR')
    const checkbox2 = ref(userData.role === 'PLSYR')
    const taksitlendirme = ref({})
    const taksitSecim = ref(null)
    const taksitTutar = ref(0)
    const taksitSayisi = ref(0)
    const snackbar = ref([])
    const form = ref(null)
    const whileStop = ref(false)
    const namaSevkDurum = ref(false)
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]
    const hasPromotion = ref(true)
    const hasMalFazlasi = ref(true)
    const scrollbar = ref()
    onMounted(() => {
      const $vuetify = getVuetify()
      if ($vuetify.breakpoint.smAndDown) scrollbar.value.destroy()
    })

    onMounted(() => {
      deliveryDate.value = store.state['app-order'].deliveryDate
      minDate.value = store.state['app-order'].deliveryMinDate
      maxDate.value =
        userData.role === 'PLSYR'
          ? store.state['app-order'].deliveryMaxDatePLSYR
          : store.state['app-order'].deliveryMaxDate
    })
    const dateChange = () => {
      store.commit('app-order/DELIVERY_DATE', deliveryDate.value)
    }
    const addressSelect = ref([])
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    watch(tab, newX => {
      watchStep()
    })

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    const counts = computed(() => Array.from({ length: 100 }, (_, i) => i + 1))

    onMounted(() => {
      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
          sepetLoading.value = false
        } else {
          // console.table('hatalı =>', response)
        }
      })
      store
        .dispatch('app-order/fetchAddressList', {
          method: 'getAddressListByUid',
          uid: '1039',
        })
        .then(response => {
          if (response.error === 0) {
            addressItems.value = response.response
            errorShow.value = false

            //  console.table('başarılı =>', response)
          } else {
            errorShow.value = true
            console.table('hatalı =>', response)
          }
        })
      store
        .dispatch('app-order/fetchBankaList', {
          method: 'getBankaTransferList',
        })
        .then(response => {
          if (!response.error) {
            bankalar.value = response.detail

            console.table('başarılı =>', response)
          } else {
            console.table('hatalı =>', response)
          }
        })
    })

    const refreshBasket = () => {
      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
        } else {
          //   console.table('hatalı =>', response)
        }
      })
    }

    const isRamazanEvent = (uid, qty, paymCond, isRamazan) => {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid,
          qty,
          paymCond,

          isRamazan,
        })
        .then(response => {
          if (response.error) {
          } else {
          }
        })
    }

    watch(
      () => store.getters['app-order/doneDelivery'],
      () => {
        refreshBasket()
      },
    )

    const closeBasket = () => {
      store.commit('app/TOGGLE_BASKET_DRAWER', false)
    }

    const deleteBasketItem = (baslik, id) => {
      store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', false)
      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchDeleteBasketItem', { id }).then(response => {
            console.log(id)
            if (response.error === 0) {
              snackbar.value.push({
                message: `${baslik} Ürünü Silindi`,
                color: 'error',
                timeout: 3000,
              })
              store.dispatch('app-order/fetchActiveBasketList').then(response1 => {
                if (!response.error) {
                  shoppingCartItems.value = response1
                  if (typeof response1.detail === 'undefined') {
                    store.commit('app/TOGGLE_BASKET_DRAWER', false)
                  }

                  //     store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', true)
                } else {
                  store.commit('app/TOGGLE_BASKET_DRAWER', false)
                }
              })
            } else {
              Vue.swal({
                title: 'Hata',
                html: response.msg,
                icon: 'error',
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            }
          })
        }
      })
    }

    const trashBasket = () => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Sepeti Temizlemek İstiyormusunuz',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: 'Sepet Temizlendi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            })
          })
        }
      })
    }

    const sleep = ms =>
      new Promise(resolve => {
        setTimeout(resolve, ms)
      })

    const backBasket = val => {
      const container = document.querySelector('#container')
      container.scrollTop = 0
      basketStep.value = val
    }
    const responseStep2 = ref({})
    const onBilgilendirmeSozlesmesi = ref(null)
    const mesafeliSatisSozlesmesi = ref(null)

    const watchStep = async () => {
      let payloadPay = {}
      if (tab.value === 0 || tab.value === 1) {
        payloadPay = {
          method: 'makeOrderStep2',
          paymentType: 6,
          /*
          kk_adsoyad: kredikartiadsoyad.value || 'mehmet tali',
          kk_ay: kredikartiay.value || '12',
          kk_yil: kredikartiyil.value || '2030',
          kk_cvv: kredikarticvv.value || '000',
          kk_no: kredikartino.value.replaceAll(' ', '') || '4506347048543223',
          selectedInstallment: kredikartiinstallment.value?.[taksitSecim.value]?.id || '130',
          bankaKodu: bankaKodu.value || '0015',
          cardFamily: cardFamily.value || 'World',
          cardBankName: cardBankName.value || 'Vakıfbank',
          isMultiCard: tab.value === 1 ? 1 : 0,
          multiCardAmount: tab.value === 1 ? (isMulti.value ? ikinciKart.value : birinciKart.value) : 0,
          */
        }
      } else if (tab.value === 2) {
        payloadPay = {
          method: 'makeOrderStep2',
          paymentType: 6,
          banka: bankalar.value[bankItem.value]?.id,
        }
      } else if (tab.value === 3) {
        payloadPay = {
          method: 'makeOrderStep2',
          paymentType: 6,
        }
      }
      if (tab.value === 1) {
        buttonText.value = isMulti.value ? 'İkinci Kart İle Devam' : 'Birinci Kart İle Devam'
      } else {
        buttonText.value = 'Alışverişi Tamamla'
      }
      store.dispatch('app-order/fetchMakeOrderStep2', payloadPay).then(() => {
        store.dispatch('app-order/fetchGetOrderStep3Data').then(response => {
          mesafeliSatisSozlesmesi.value = response.mesafeliSatisSozlesmesi
          onBilgilendirmeSozlesmesi.value = response.onBilgilendirmeSozlesmesi
          const container = document.querySelector('#container')

          container.scrollTop = 0
          cargoPrice.value = responseStep2.value.detail?.ordering?.totalCargoAmount || 0
          basketStep.value = 3
          toplamOdenecekTutar.value = responseStep2.value.detail?.ordering?.totalAmount || 0
          toplamOdenecekTutarHavale.value = responseStep2.value.detail?.ordering?.totalAmount || 0
          loading.value = false
        })
      })
    }

    const basketStep2 = () => {
      loading.value = true
      const payload = {
        hasPromotion: hasPromotion.value,
        hasMalFazlasi: hasMalFazlasi.value,
      }
      store
        .dispatch('app-order/fetchGetOrderStep1Data', payload)
        .then(response => {
          if (response.error === 0) {
            const container = document.querySelector('#container')
            container.scrollTop = 0
            basketStep.value = 2
          } else {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',

              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const tabKKDisabled = ref(false)
    const tabHavaleDisabled = ref(false)
    const tabCHDisabled = ref(false)
    const tabCHnone = ref(false)
    if (userData.role === 'PLSYR') {
      tabCHnone.value = true
    }
    const basketStep3 = () => {
      loading.value = true
      if (!namaSevkDurum.value) {
        namaSevkAddressItems.value = []
      }
      if (store.state.paymentType === 'O1P') {
        tab.value = 0
        tabKKDisabled.value = false
        tabHavaleDisabled.value = false
        tabCHDisabled.value = true
      } else if (store.state.paymentType === 'O1') {
        tab.value = 3
        tabKKDisabled.value = true
        tabHavaleDisabled.value = true
        tabCHDisabled.value = false
      } else {
        tab.value = 0
        tabKKDisabled.value = false
        tabHavaleDisabled.value = false
        tabCHDisabled.value = false
      }

      const payload = {
        deliveryAddr:
          namaSevkAddressItems.value?.[addressSelect.value]?.id || addressItems.value?.[addressSelect.value]?.id,
        invoiceAddr:
          namaSevkAddressItems.value?.[addressSelect.value]?.id || addressItems.value?.[addressSelect.value]?.id,
      }
      store.dispatch('app-order/fetchMakeOrderStep1', payload).then(result => {
        if (result.error === 0) {
          store.dispatch('app-order/fetchGetOrderStep2Data').then(response => {
            responseStep2.value = response
            watchStep()
          })
        } else {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            html: result.msg,
            icon: 'error',

            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
    }
    const orderStatus = ref()
    const paymentErrorMsg = ref(null)
    const paymentError = ref(false)
    let iframe

    const krediKartTemizle = ref(0)
    window.addEventListener('message', e => {
      if (e.data.success) {
        dialog.value = false
        iframe.remove()

        if (e.data.ismulti) {
          if (e.data.iscomplete) {
            store.commit('app/TOGGLE_BASKET_DRAWER', false)
            router.push({ name: 'order-completed' })
          } else {
            isMulti.value = true
            ikinciKart.value = e.data.multicardremainamount
            buttonText.value = 'İkinci Kart İle Devam'
            krediKartTemizle.value += 1
          }
        } else {
          store.commit('app/TOGGLE_BASKET_DRAWER', false)
          router.push({ name: 'order-completed' })
        }
      }
    })
    const basketStepCompleted = async () => {
      paymentErrorMsg.value = ''
      paymentError.value = false
      if (tab.value === 0 || tab.value === 1) {
        if (!form.value.validate()) {
          return
        }
      } else if (!(checkbox1.value && checkbox2.value)) {
        if (!form.value.validate()) {
          return
        }
      }
      loading.value = true

      await watchStep()
      store.dispatch('app-order/fetchMakeOrderStep3').then(async response => {
        if (response.error === 0) {
          store.commit('app/TOGGLE_BASKET_DRAWER', false)
          router.push({ name: 'order-completed' })
        } else {
          paymentErrorMsg.value = response.msg
          paymentError.value = true
          Vue.swal({
            title: 'Hata',
            html: response.msg,
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
    }
    const namaSevkMusteri = ref()
    const namaSevkMusteriItems = ref([])

    const namaSevkAddressItems = ref([])

    const paymentCancel = () => {
      orderStatus.value = null
      paymentErrorMsg.value = null
      paymentError.value = false
      loading.value = false
      iframe.remove()
      whileStop.value = true
      orderStatus.value = null
      dialog.value = false
    }
    postData({
      method: 'getCustomersBySalDept',
      type: 'select',
    }).then(response => {
      namaSevkMusteriItems.value = response.detail
    })

    const namaSevkMusteriChange = () => {
      postData({
        method: 'getAddressListByCustNo',
        customer: namaSevkMusteri.value,
      }).then(response => {
        if (response.error === 0) {
          namaSevkAddressItems.value = response.response
        } else {
          namaSevkAddressItems.value = []
        }
      })
    }

    const desserts = ref([])
    const fetchBalance = () => {
      const method = 'getCustomerBalanceDetailed'
      const customer = store.state.plasiyerCustomer

      store
        .dispatch('postMethod', { method, customer })
        .then(response => {
          if (response.error === 0) {
            userData.value = response.balance[0]
            toplamOdenecekTutar.value =
              -1 * (Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL))
            desserts.value = [
              {
                name: 'Bakiye',
                tutar: response.balanceDetail.head[0].TBLVAL,
              },
              {
                name: 'Faturalanmamış Sipariş',
                tutar: response.balanceDetail.head[1].TBLVAL,
              },
              {
                name: 'Kendi Çeki/Senedi',
                tutar: response.balanceDetail.head[3].TBLVAL,
              },
              {
                name: 'Müşteri Çeki/Senedi',
                tutar: response.balanceDetail.head[10].TBLVAL,
              },
              {
                name: 'Toplam Risk',
                tutar: response.balanceDetail.head[15].TBLVAL,
              },

              {
                name: 'Risk Limiti',
                tutar: response.balanceDetail.head[6].TBLVAL,
              },
              {
                name: 'Kalan Açık Tutar',
                tutar: Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL),
              },
            ]

            pageLoading.value = false
          }
        })
        .catch(error => {
          if (error.response?.status === 404) {
            userData.value = {}
          }
        })
    }

    onMounted(() => {
      if (userData.role === 'PLSYR') {
        fetchBalance()
      }
    })

    const buttonDisabled = ref(false)
    watch(birinciKart, val => {
      const odeme = taksitTutar.value || toplamOdenecekTutar.value
      ikinciKart.value = (odeme - val).toFixed(2)
      if (ikinciKart.value < 0.1) {
        ikinciKart.value = 'Hata'
        buttonDisabled.value = true
      } else {
        buttonDisabled.value = false
      }
    })

    return {
      hasMalFazlasi,
      hasPromotion,
      krediKartTemizle,
      buttonDisabled,
      buttonText,
      birinciKart,
      ikinciKart,
      paymentCancel,
      paymentErrorMsg,
      paymentError,
      desserts,
      namaSevkMusteriChange,
      namaSevkAddressItems,
      userData,
      namaSevkDurum,
      namaSevkMusteri,
      namaSevkMusteriItems,
      mesafeliStatus,
      onBilgilendirmeStatus,
      form,
      sepetLoading,
      dialog,
      statusFind,
      basketStep2,
      backBasket,
      cargoPrice,
      formValidate,
      basketStepCompleted,
      buyerNote,
      basketStep3,
      counts,
      isRamazanEvent,
      bankItem,
      tab,
      perfectScrollbarOptions,
      shoppingCartItems,
      addressItems,
      errorShow,
      deleteBasketItem,
      musteri,
      number,
      mesafeliSatisSozlesmesi,
      onBilgilendirmeSozlesmesi,
      fabrika,
      tarih,
      checkbox1,
      checkbox2,
      basketStep,
      closeBasket,
      refreshBasket,
      addressSelect,
      toplamOdenecekTutar,
      toplamOdenecekTutarHavale,
      taksitlendirme,
      taksitSecim,
      deliveryDate,
      menuDeliveryDate,
      minDate,
      maxDate,
      dateChange,
      loading,
      bankalar,
      valid,
      trashBasket,
      cardNumberValidate,
      cardNameValidate,
      cardMountValidate,
      cardYearValidate,
      cardCvvValidate,
      kkAdSoyad,
      kkAy,
      kkYil,
      kkCvv,
      kkNo,
      kredikartino,
      kredikartiadsoyad,
      kredikartiay,
      kredikartiyil,
      kredikarticvv,
      kredikartiinstallment,
      selectedInstallment,
      bankaKodu,
      cardFamily,
      cardBankName,
      taksitTutar,
      taksitSayisi,
      snackbar,
      scrollbar,

      tabKKDisabled,
      tabHavaleDisabled,
      tabCHDisabled,
      tabCHnone,
      isMulti,
      icons: {
        mdiText,
        mdiChevronDown,
        mdiChevronUp,
        mdiClose,
        mdiTruckFast,
        mdiTrashCan,
        mdiContactlessPayment,
        mdiRefresh,
        mdiBasket,
        mdiCalendar,
        mdiCreditCard,
        mdiCreditCardPlus,
        mdiBank,
        mdiMapMarker,
        mdiBasketOff,
        mdiCircle,
      },
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Ad',
          align: 'right',
          sortable: false,
          value: 'name',
        },

        {
          text: 'Değer',
          sortable: false,
          align: 'right',
          value: 'tutar',
        },
      ],
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
    }
  },
  computed: {
    satirToplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.satirToplamFiyat
    },
    satirToplamKDV() {
      return this.shoppingCartItems?.toplamlar?.satirToplamKDV
    },
    toplamBrutFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamBrutFiyat
    },
    toplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamFiyat
    },
    toplamIndirimTutari() {
      return this.shoppingCartItems?.toplamlar?.toplamIndirimTutari
    },
    urunSayisi() {
      return this.shoppingCartItems?.toplamlar?.urunSayisi
    },

    toplamPaketSayisi() {
      return this.shoppingCartItems?.toplamlar?.toplamPaketSayisi
    },

    address_title() {
      let result = null
      if (this.addressItems.length > 0) {
        result = this.addressItems[this.addressSelect]?.address_title
      }

      return result
    },
  },
  watch: {
    krediKartTemizle(val) {
      this.formData = {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      }
    },
    taksitSecim(val) {
      this.toplamOdenecekTutar = this.taksitlendirme.installment[val].inst_tot_amount

      this.taksitTutar = this.taksitlendirme.installment[val].inst_amount
      this.taksitSayisi = this.taksitlendirme.installment[val].installment_text.charAt(0)
    },
    tab(val) {
      // if (val === 0) {
      //   this.toplamOdenecekTutar = this.taksitlendirme?.installment[this.taksitSecim]?.inst_tot_amount
      // } else {
      //   this.toplamOdenecekTutar = 0
      // }
    },
  },
  methods: {
    updateCardNumber(val) {
      if (val.length > 18) {
        const payload = {
          method: 'getInstallmentsByBin',
          kk_no: val.replaceAll(' ', ''), // 4506347048543223
          price: this.toplamOdenecekTutar,
        }
        this.$store.dispatch('app-order/fetchBinList', payload).then(response => {
          this.taksitlendirme = response

          this.cardBankName = response.card_bank_name
          this.cardFamily = response.card_family
          this.bankaKodu = response.card_bank_name
          this.kredikartiinstallment = response.installment
        })
        this.cardNumberValidate = true
        this.kkNo = val
        this.kredikartino = val
      } else {
        this.taksitlendirme = {}
        this.cardNumberValidate = false
      }
    },
    updateCardName(val) {
      if (val) {
        this.cardNameValidate = true
        this.kkAdSoyad = val
        this.kredikartiadsoyad = val
      } else {
        this.cardNameValidate = false
      }
    },
    updateCardMonth(val) {
      if (val) {
        this.cardMountValidate = true
        this.kkAy = val
        this.kredikartiay = val
      } else {
        this.cardMountValidate = false
      }
    },
    updateCardYear(val) {
      if (val) {
        this.cardYearValidate = true
        this.kkYil = val
        this.kredikartiyil = val
      } else {
        this.cardYearValidate = false
      }
    },
    updateCardCvv(val) {
      if (val.length >= 3) {
        this.cardCvvValidate = true
        this.kkCvv = val
        this.kredikarticvv = val
      } else {
        this.cardCvvValidate = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/style.scss';
// Set Perfect Scrollbar Container Height
.vertical-shopping-cart-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 35px) !important;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: calc(100%) !important;
    }
  }
}
.my-alert .v-alert__content {
  color: rgb(155, 122, 72) !important;
}
.v-stepper__step__step {
  display: none !important;
}
.v-application--is-ltr .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 0 !important;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 0px !important;
}
</style>
