import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
  app: {
    name: 'Sinangil',
    textLogo: require('@/assets/images/logos/hatko-logo.png'),
    logoDark: require('@/assets/images/svg/logoDark.svg'),
    textLogo: require('@/assets/images/logos/hatko-logo.png'),
    textLogoDark: require('@/assets/images/svg/textLogoDark.svg'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'fade-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'default', // default, bordered, semi-dark
    contentWidth: 'full',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#8d8794',
      grey: '#fafafa',
      night: '#8d8794',
      nightDark: '#efeef0',
      nightDarkTon: '#C8C8C8',
      secondary: '#0083e8',
      'secondary-light': '#0075cf',
      tertiary: '#303F9F',
      base: '#01c1f4',
      accent: '#50CDC2',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
      counter: '#efeef0',
      chip: '#8d8794',
    },
    dark: {
      primary: '#aaa6bf',
      grey: '#3b355a',
      night: '#312d4b',
      nightDark: '#28243d',
      nightDarkTon: '#2d2844',
      secondary: '#0083e8',
      'secondary-light': '#0075cf',
      tertiary: '#303F9F',
      base: '#01c1f4',
      accent: '#16B88A',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
      counter: '#efeef0',
      chip: '#28243d',
    },
  },
}

export default themeConfig
