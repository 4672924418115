// eslint-disable-next-line object-curly-newline
import i18n from '@/plugins/i18n'
import {
  mdiAccountSupervisor,
  mdiAccountTie,
  mdiBullhorn,
  mdiCheckCircleOutline,
  mdiCog,
  mdiCollage,
  mdiCreditCard,
  mdiCrosshairs,
  mdiDownload,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiFileLock,
  mdiFormatIndentIncrease,
  mdiHomeOutline,
  mdiMapMarker,
  mdiOrderBoolAscending,
  mdiShieldAccount,
  mdiViewList,
} from '@mdi/js'

const accessToken = localStorage.getItem('accessToken')
const dynamic = localStorage.getItem('lang') == 'tr' ? 'Eğitim Dokümanı İndir' : 'Download Instruction Document'

export default [
  {
    title: i18n.t('denetimMasasi'),
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    subheader: 'Hesaplar',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Yöneticiler',
    icon: mdiAccountTie,
    to: 'admin-list',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Kullanıcılar',
    icon: mdiAccountSupervisor,
    to: 'user-list',
    resource: 'Users',
    action: 'read',
  },
  {
    subheader: i18n.t('siparis'),
    resource: 'KPanel',
    action: 'read',
  },
  {
    subheader: 'Ürün & Takip',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Ürünler',
    icon: mdiCollage,
    to: 'product-list',
    resource: 'ProductsMan',
    action: 'read',
  },
  /*
  {
    title: 'Sipariş Ver',
    icon: mdiToolbox,
    to: 'order-to',
    resource: 'Products',
    action: 'read',
  },
   {
    title: 'Kolay Sipariş Ver',
    icon: mdiFlash,
    to: 'order-easy',
    resource: 'Products',
    action: 'read',
  },
  */

  {
    title: i18n.t('siparisGiris'),
    icon: mdiFormatIndentIncrease,
    to: 'siparis-giris',
    resource: 'Products',
    action: 'read',
  },

  {
    title: i18n.t('siparisOnay'),
    icon: mdiCheckCircleOutline,
    to: 'order-confirm',
    resource: 'Products',
    action: 'read',
  },
  {
    title: i18n.t('acikSiparisler'),
    to: 'open-order',
    icon: mdiOrderBoolAscending,
    resource: 'KPanel',
    action: 'read',
  },
  /*
   {
    title: i18n.t('siparisTakip'),
    icon: mdiOrderNumericAscending,
    to: 'order-tracking',
    resource: 'Products',
    action: 'read',
  },
  {
    subheader: 'Ödeme',
    resource: 'KPanel',
    action: 'read',
  },
 
  {
    title: 'Manuel Ödeme',
    icon: mdiContactlessPayment,
    to: 'payment',
    resource: 'Payment',
    action: 'read',
  },
  */

  {
    title: 'Kart Hareketleri',
    icon: mdiCreditCard,
    to: 'credit-card-transactions',
    resource: 'PaymentActions',
    action: 'read',
  },
  {
    subheader: i18n.t('hesap'),
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: i18n.t('kullaniciBilgilerim'),
    icon: mdiShieldAccount,
    to: { name: 'user-view' },
    resource: 'KPanel',
    action: 'read',
  },
  {
    title: i18n.t('adreslerim'),
    icon: mdiMapMarker,
    to: { name: 'user-addresses' },
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: i18n.t('sozlesmelerim'),
    icon: mdiFileLock,
    to: 'user-contracts',
    resource: 'KPanel',
    action: 'read',
  },
  {
    subheader: 'Sistem',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Duyurular',
    icon: mdiBullhorn,
    to: 'event-list',
    resource: 'EventsMan',
    action: 'read',
  },

  {
    subheader: i18n.t('raporlama'),
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: i18n.t('satisBelgeleri'),
    icon: mdiFileDocument,
    resource: 'KPanel',
    action: 'read',
    children: [
      {
        title: i18n.t('siparis'),
        to: { name: 'documents-list', params: { folder: 'order' } },
        resource: 'KPanel',
        action: 'read',
        titleColor: 'blue',
      },
      {
        title: i18n.t('irsaliye'),
        to: { name: 'documents-list', params: { folder: 'waybill' } },
        resource: 'KPanel',
        action: 'read',
      },
      {
        title: i18n.t('fatura'),
        to: { name: 'documents-list', params: { folder: 'bill' } },
        resource: 'KPanel',
        action: 'read',
      },
    ],
  },

  {
    title: i18n.t('urunListesi'),
    icon: mdiViewList,
    to: 'urun-liste',
    resource: 'Products',
    action: 'read',
  },

  {
    title: dynamic,
    icon: mdiDownload,
    to: 'download-doc',
    resource: 'Products',
    action: 'read',
    target: '_blank',
  },

  /*
   {
    title: 'Eğitim Dokümanı İndir',
    icon: mdiDownload,
    to: 'doc',
    resource: 'Products',
    action: 'read',
  },


    {
    title: i18n.t('Ekstre'),
    icon: mdiTrayFull,
    to: 'extract-list',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Toplu SMS',
    icon: mdiMessageProcessing,
    to: 'sms-list',
    resource: 'Users',
    action: 'read',
  },
  */

  {
    title: 'Loglar',
    icon: mdiCrosshairs,
    to: 'logs',
    resource: 'Logs',
    action: 'read',
  },
  {
    title: 'Ayarlar',
    icon: mdiCog,
    to: 'settings-view',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Integrations',
    href: `https://portalapi.hatko.com/tr/integrations?token=${accessToken}`,
    icon: mdiFileDocumentOutline,
    resource: 'GeneralSettings',
    action: 'read',
  },
]
